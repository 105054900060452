<template lang="html">
  <section class="newsletter documentos column-start">
    <router-link :to="{ name: 'home'}" class="volver">Volver al listado</router-link>
    <h2 class="subtitulo-documentos row-center">
      <span></span>Newsletter<span></span>
    </h2>
    <section class="contenedor-buscador row-center">
      <input
        class="input-styled"
        type="text"
        placeholder="Buscar por nombre"
        id="searchTool"
        v-model="textSearch"
        @keyup="searchTimeOutSearch"
      />
      <!-- <button @click="search" class="btn-search fa fa-search">Buscar</button> -->
    </section>

    <template v-if="loading">
      <p class="resultados-busqueda">Cargando...</p>
    </template>
    <template v-else>
      <template v-if="Object.keys(news).length != 0">
        <div class="listado-newsletter row-start fww aifs">
          <newEl v-for="newEl in news" :key="newEl.slug" :newContent="newEl" />
        </div>
        
       
        <div class="row-center" v-if="NewsMaxItems != Object.keys(news).length">
          <span @click="scrollEnd" class="ver">Cargar más </span>
        </div>
      </template>

      <template v-else>
        <div>
          <p class="resultados-busqueda">No se han encontrado resultados.</p>
        </div>
      </template>
    </template>
    
     <template v-if="loading">
       <div class="fixed-loading">
          <img src="/img/loading.gif" alt="">
        </div>
      </template>
  </section>
</template>

<script lang="js">
  import { mapGetters, mapActions } from 'vuex';
  import store from '@/store'
  import newEl from '@/components/new-el.vue'
  export default  {
    name: 'newsletter',
    props: [],
     data () {
      return {
        textSearch: '',
        timer : null,
        page: 1
      }
    },
    computed: {
      ...mapGetters({
        news : 'contents/getNews',
        loading : 'contents/getLoading',
        reachEnd: 'contents/getNewsReachEnding',
        getNewsMaxItems : 'contents/getNewsMaxItems'
      }),
      NewsMaxItems() {
      return this.getNewsMaxItems;
      }
    },
    mounted () {
      this.textSearch = store.getters['contents/getNewsLastFilter'];
      this.loadNews({ textFilter: this.textSearch, page: this.page });
      //window.onscroll = () => {
      // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight
      //     && this.loading == false
      //     && this.reachEnd == false
      // ) {
      //   this.page++;
      //   this.loadNews({ textFilter: this.textSearch, page: this.page });
      // }
      //}
    },

    methods: {
    ...mapActions({
      loadNews : 'contents/loadNews'
    }),
      searchTimeOutSearch() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.page = 1;
          this.loadNews({ textFilter: this.textSearch });
        }, 1000);
      },
      scrollEnd() {
        this.page++;
        this.loadNews({ textFilter: this.textSearch, page: this.page });
      }
    },

    components:{
      newEl
    },
    watch: {
      'textSearch'(){
        if(this.textSearch == ""){
            this.loadNews()
        }
      }
    }
}
</script>

<style scoped lang="scss">

</style>
