<template lang="html">
  <article class="newsletter-el">
    <router-link
      :to="{ name: 'new', params: { slug: newContent.slug } }"
    >
      <div class="contenedor-imagen" :style="`background-image:url(` + newContent.header_img.url + `)`">
        <!-- <img
         :src="newContent.header_img.url"
          :alt="'imagen noticia' + newContent.title"
        /> -->
      </div>

      <div class="contenedor-cuerpo">
        <p class="fecha">{{currentDateTime(newContent.order_date)}}</p>

        <h2 class="titulo">{{ newContent.title }}</h2>

        <p class="cuerpo">
         {{ newContent.header }}
        </p>

        <span class="ver"> Ver </span>
      </div>
    </router-link>
  </article>
 
</template>
<script lang="js">
  import moment from 'moment'
  export default  {
    name: 'new-el',
    props: ['newContent'],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {
      currentDateTime(date) {
        return moment(date).locale('es').format('L')
      },
    },
    computed: {

    }
}
</script>

<style scoped>
.new-el {
}
</style>
